/* eslint-disable no-global-assign */
typeof window !== 'undefined' &&
  (Worker = ((BaseWorker: typeof Worker) =>
    class Worker extends BaseWorker {
      constructor(scriptURL: string | URL, options?: WorkerOptions) {
        const url = String(scriptURL);
        super(
          // Check if the URL is remote
          url.includes('://') && !url.startsWith(location.origin)
            ? // Launch the worker with an inline script that will use `importScripts`
              // to bootstrap the actual script to work around the same origin policy.
              URL.createObjectURL(
                new Blob(
                  [
                    `importScripts=((i)=>(...a)=>i(...a.map((u)=>''+new URL(u,"${url}"))))(importScripts);importScripts("${url}")`,
                  ],
                  { type: 'text/javascript' }
                )
              )
            : scriptURL,
          options
        );
      }
    })(Worker));

export {};
