import '../utils/pollyfill';
import '../styles/globals.css';
import '../utils/patch-webworker';

import Head from 'next/head';

import type { AppProps } from 'next/app';
import { AppProvider } from 'context/AppProvider';

const defaultFavIcon = 'https://assets.storylane.io/images/favicon.ico';

if (process.env.CI === 'true' || process.env.ENABLE_MOCKS === 'true') {
  require('../../tests/mocks');
}

interface AppPropsTypes {
  meta: {
    favicon?: string;
    userAgent?: string;
  };
}

function App({ Component, pageProps }: AppProps<AppPropsTypes>) {
  const favIcon = pageProps?.meta?.favicon || defaultFavIcon;

  return (
    <AppProvider userAgent={pageProps?.meta?.userAgent}>
      <Head>
        <meta name="theme-color" content="#000000" />
        <title>Storylane</title>
        <meta name="description" content="" />
        <meta name="viewport" content="maximum-scale=1.0" />

        <link rel="shortcut icon" href={favIcon} />
        <link rel="apple-touch-icon" href={favIcon} />
      </Head>

      <Component {...pageProps} />
    </AppProvider>
  );
}

export default App;
