import { BreadcrumbHint, Breadcrumb, BrowserOptions } from '@sentry/browser';

export function beforeBreadcrumb(
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint
): Breadcrumb {
  if (breadcrumb.category === 'ui.click' && hint) {
    const path = hint.event.composedPath();
    const labels = [];

    for (let i = path.length - 1; i >= 0; i--) {
      if (path[i].ariaLabel) {
        labels.push(path[i].ariaLabel);
      }
    }

    if (labels.length !== 0) {
      breadcrumb.message = labels.join(' > ');
    }
  }

  return breadcrumb;
}

export const playerBeforeSend: BrowserOptions['beforeSend'] = (event, hint) => {
  if (
    hint.originalException &&
    hint.originalException instanceof Error &&
    (hint.originalException.stack?.includes('webpack-internal') ||
      hint.originalException.stack?.includes('_next'))
  ) {
    return event;
  }

  // Returning the null if it's not related to the our codebase
  return null;
};

export const viteBeforeSend: BrowserOptions['beforeSend'] = (event, hint) => {
  if (
    hint.originalException &&
    hint.originalException instanceof Error &&
    (hint.originalException.stack?.includes('webpack-internal') ||
      hint.originalException.stack?.includes('vite'))
  ) {
    return event;
  }

  // Returning the null if it's not related to the our codebase
  return null;
};

/**
 * @description Ignore errors related to ResizeObserver loop limit just to avoid noise in Sentry dashboard
 * @todo Remove this when this issue is fixed in our codebase
 *  1. Optimizing Re-Renders in React
 *  2. Debouncing ResizeObserver Events
 */
export const resizeErrorMessagesToIgnore = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  /ResizeObserver loop limit/i,
];

/**
 * @description Ignore requests to these URLs
 * https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 */
export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
