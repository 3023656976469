import { useState, useEffect, useMemo } from 'react';

export const useMobile = (userAgent?: string) => {
  const isClient = typeof window !== 'undefined';

  const isUserAgentMobile = useMemo(
    () =>
      /Mobi|Android/i.test(userAgent || (isClient ? navigator.userAgent : '')),
    [isClient, userAgent]
  );

  const [isMobile, setIsMobile] = useState<boolean>(() => {
    return (
      isUserAgentMobile ||
      (isClient && window.matchMedia('(max-width: 667px)').matches)
    );
  });

  const [isLandscapeView, setIsLandscapeView] = useState<boolean>(() => {
    if (isClient) {
      return window.innerWidth > window.innerHeight;
    }
    return false;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 667px)').matches);
      setIsLandscapeView(window.innerWidth > window.innerHeight);
    };

    const mediaQuery = window.matchMedia('(max-width: 667px)');
    mediaQuery.addEventListener('change', handleResize);
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [isClient, isUserAgentMobile, isMobile, isLandscapeView]);

  return { isMobile, isLandscapeView };
};
